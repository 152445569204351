<template>
  <div class="coursepanel-wrapper">
    <!-- <navbar></navbar>
    <div class="coursepanel-inner flex" :style="{ 'height': !!is_web ? 'calc(100% - 40px)' : 'calc(100% - 80px)' }">
      <sidebar></sidebar>
      <app-main></app-main>
    </div> -->
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { Navbar, Sidebar, AppMain } from './components'

export default {
  name: 'layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
    }),
  },
  watch: {
    '$route': {
      deep:true,
      handler() {
        this.INIT_COURSE_RELATIVE({course_id:this.$route.params.id});
      }
    }
  },
  created() {
    //获取通用设置
    this.GetSchema();
    // 初始化课程空对象
    this.INIT_COURSE_RELATIVE({course_id:this.$route.params.id});
  },
  methods: {
    ...mapMutations(["INIT_COURSE_RELATIVE"]),
    ...mapActions(["GetSchema"],)
  },
}
</script>

<style  lang="less" scoped>
  .coursepanel-wrapper {
    box-sizing: border-box;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden; //导致宽屏时，不显示最外层的滚动条
  }
  // 主体区域
  .coursepanel-inner {
    // transition: margin-left .28s;
    // margin-left: 220px;
  }
</style>
